import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import './App.module.scss'

window['global'] = window
import { ChatScreenSkeleton } from 'common/UI/LoadingSkeleton'
import { ThemeProvider } from 'common/contexts/themeContext/themeContext'
import ErrorBoundary from 'common/UI/ErrorBoundary'
import { lazyWithErrorHandling } from 'modules/Auth/store/lazy'

const App = lazyWithErrorHandling(() => import('./App'))

const ErrorCatcher = () => {
  return (
    <HelmetProvider>
      <ErrorBoundary componentName="MainApp">
        <App />
      </ErrorBoundary>
    </HelmetProvider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Suspense fallback={<ChatScreenSkeleton stage={1} />}>
    <ThemeProvider>
      <ErrorCatcher />
    </ThemeProvider>
  </Suspense>
)
